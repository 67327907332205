.subscriptions-page {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.subscriptions-table__cells-template {
  display: grid;
  grid-template-columns: 74px auto 152px 60px 110px 110px 40px;
  column-gap: 4px;
}

#subs-table {
  row-gap: 8px;

  .table__row {
    .table__cell {
      height: 100%;
      padding: 10px 5px;
      color: $blackSecondColor;
    
      &:first-of-type {
        padding-left: 16px;
      }
    
      &:last-of-type {
        padding-left: 14px;
        padding-right: 13px;
      }
    }
    
    &.active, &.active:hover {
      border: 1px solid #517EF2;
      z-index: 1;

      .checkbox {
        @include flex-center;
        border: 1px solid $activeSecondColor;

        div {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $activeSecondColor;
        }
      }
    }
  }
}



@media (max-width: 1180px) {
  .subscriptions-page {
    margin-left: 32px;
  }
}