.without-files {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  h4 {
    @extend .header-font-m;
    color: $activeColor;
    padding: 0 10px;
    text-align: center;
  }
}

.without-files__text {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.dropzone.has-error {
  .without-files .dropzone-text, .without-files h4 {
    color: $blackSecondColor;
    opacity: .3;
  }
  .files-load-image {
    opacity: .3;
    display: none;
    
    &.files-load-image__has-error {
      display: unset;
    }
  }

  &::before {
    font-size: 13px;
    top: calc(6px + 100%);
    right: 0;
    left: 0;
    text-align: center;
    font-weight: 600;
  }
}

.files-load-image {

  &.files-load-image__has-error {
    display: none;
  }
}

.new-loaded_files-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.new-loaded_file {
  cursor: auto;
  width: 109px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4px;
  margin: 25px 24px 20px;
}

.new-loaded_file-img {
  background: url("../img/files-done.png") no-repeat 50% 50%;
  width: 51px;
  height: 56px;
}

.new-loaded_file-name {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  text-align: center;
  margin-bottom: 4px;
  margin-top: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-loaded_file-type {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #6F787B;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-loaded_file-close-btn {
  position: absolute;
  padding: 0;
  @include flex-center;
  width: 22px;
  height: 22px;
  background: #FFFFFF;
  border-radius: 50%;
  top: -12px;
  right: -12px;
  cursor: pointer;

  &:hover, &:focus {
    i{
      color: hsl(0deg 85% 70%);
    }
  }
  
  i {
    color: rgba(0, 0, 0, 0.1);
    font-size: 22px;
    font-weight: 900;
    transition: color ease .25s;
  }
}

.new-loaded_file-favorite-btn {
  position: absolute;
  width: 20px;
  height: 20px;
  box-sizing: content-box;
  margin: -5px;
  padding: 5px;
  top: -11px;
  left: -11px;
  border-radius: 50%;
  // z-index: 12;

  &:hover, &:focus {
    .new-loaded_file-favorite-btn-inner {
      opacity: 1;
      background-image: linear-gradient(white, white), linear-gradient(45deg, hsl(43, 74%, 49%) 33%, hsl(51, 100%, 50%) 66%, hsl(39, 100%, 50%));
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-color: transparent
    }
  }

  .new-loaded_file-favorite-btn-inner {
    @include flex-center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 1.6px hsl(0, 0%, 90%);
    opacity: 0;
    transition: ease .25s all;
    background: #FFFFFF;
    outline: white solid 1.4px;

    &.active {
      opacity: 1;
    }
  }

  i {
    font-size: 16px;
    background: linear-gradient(45deg, hsl(43, 74%, 49%) 33%, hsl(51, 100%, 50%) 66%, hsl(39, 100%, 50%));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: hsl(0deg 0% 0% / 0%);
    color: hsl(217deg 74% 16%);
  }
}

.dropzone {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px dashed rgba(74, 82, 103, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  // padding: 15px 0;
  position: relative;
  height: 100%;
  max-height: 100%;
  min-height: 0;

  &.dropzone--loading {
    cursor: unset;
  }

  &:focus {
    border-color: $activeColor;
  }

  &.has-error {
    border-color: $redColor;
  }
}

.ms-product-media {
  @extend .dropzone;
  border-style: solid;
  cursor: unset;
  flex-direction: row;
}

.dropzone-text {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #6F787B;
  font-weight: 400;
  padding: 0 15px;
}

.product-page-dropzone__zone {
  min-height: 173px;
}
.product-page-dropzone__zone .dropzone-text{
  width: 382px;
}

.dropzone-text.small {
  display: none;
}

.dropzone-text span {
  color: #0368AF;
}

.dropzone-help-message {
  position: absolute;
  bottom: 3px;
  color: #6F787B;
  font-size: 12px;
}

.photos-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photos-container-photo-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  padding: 0 10px;
  row-gap: 10px;
}

.photos-container-photo {
  max-width: 100%;
  padding: 20px 5px;  
  display: flex;
  // width: 160px;

  &:hover {
    // .new-loaded_file-favorite-btn {
    //   border: white 1.4px solid;
    // }

    .new-loaded_file-favorite-btn-inner:not(.active):not(:hover) {
      opacity: 0.4;
    }
  }  
}

.new-loaded_file-image-container {
  position: relative;
  width: 146px;
  height: 146px; 
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  margin: auto;
}

.new-loaded_file-image-wrapper {
  @include flex-center;
  height: 100%;
  width: 100%;
  padding: 2px;
  overflow: hidden;
}

.new-loaded_file-image-container .new-loaded_file-close-btn svg {
  width: 55.5%;
  height: 55.5%;
}

.new-loaded_file-image-container img {
  font-size: 10px;
  overflow-wrap: break-word;
  pointer-events: none;
  user-select: none;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dropzone.variant-dropzone {
  min-height: unset;
  height: 100%;
  padding: unset;
  border: none;

  .new-loaded_file-close-btn {
    width: 14px;
    height: 14px;
    top: -7px;
    right: -7px;

    i {
      font-size: 14px;
    }
  }
}

.variant-dropzone-inner {
  max-height: 56px;

  &.disabled {
    cursor: not-allowed;
  }
}

@media(max-width: 1180px) {
  .dropzone-text {
    &.small {
      display: unset;
    }

    &.large {
      display: none;
    }
  }
}