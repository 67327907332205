
.collections {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.collections__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collections__table {
  .table__header {
    height: 48px;
  }
}

.table__row.collections__row {
  height: 88px;
}

.table__row.collections__row,
.table__header.collections__row {
  display: grid;
  grid-template-columns: 60px 56px auto 90px 70px 72px;
  column-gap: 15px;
  border: none;
  border-bottom: 1px solid #E4E4E4;

  .checkbox-wrapper {
    justify-content: flex-end;
  }

  .control-cell {
    justify-content: right;
    column-gap: 20px;
    height: 100%;
  }
}

.collections__add-items-rows--template.row-item {
  display: grid;
  grid-template-columns: 16px 56px auto 15% 15%; 
  padding-left: 24px;
  padding-right: 24px;
  column-gap: 2%;
}

.collection__table-header--control {
  display: grid;
  grid-template-columns: 60px auto auto;
  column-gap: 20px;
  height: 48px;
  padding-right: 31px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .trash-btn {
    justify-content: flex-end;
    color: #D75A5A;
  }
}

.collection-page {
  display: grid;
  grid-template-columns: auto 288px; 
  grid-template-areas: 
  'title title'
  'summary sidebar'
  'summary .'
  'products .'
  'seo .';
  row-gap: 24px;
  column-gap: 16px;
  position: relative;
  overflow: visible;

  & .seo {
    grid-area: seo;
  }
}

.collection-page__header {
  display: flex;
  column-gap: 23px;
  align-items: center;
  grid-area: title;
}

.collection-page__summary {
  display: grid;
  grid-area: summary;
  grid: 
  "inputs dnd" 
  "desc desc" / 2fr 1fr;
  gap: 24px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 24px;
  padding-bottom: 32px;
}

.collection-page__summary__title {
  grid-area: title;
}
.collection-page__summary__inputs-wrapper {
  display: grid;
  grid-area: inputs;
  grid-template-rows: auto auto auto;
  gap: 16px 24px;

  .grid-element {
    &:nth-child(1) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
    &:nth-child(2) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      margin-bottom: 26px;
    }
    &:nth-child(3) {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
    &:nth-child(4) {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
    }
  }
}

.collection-page__summary__navs {
  display: grid;
  grid-template-columns: 20% auto;
  column-gap: 16px;
  
  .grid-element {
    &:nth-child(1) {
      grid-column: 1 / 2;
    }
    &:nth-child(2) {
      grid-column: 2 / 3;
    }
  }
}

.collection-page__summary__image {
  padding-top: 26px;
  height: 262px;
  grid-area: dnd;
}
.collection-page__summary__description {
  margin-top: 24px;
  grid-area: desc;
}

.collection-page__products {
  grid-area: products;

}

.collection-page__products__wrapper {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.col-products__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #E4E4E4;
}

.col-products__header-btns {
  display: flex;
  column-gap: 12px;
}

.collection-page__add-product {
  padding-left: 12px;
  padding-right: 12px;
  color: $activeSecondColor;
}

.collection-page__products__inner {
  .row-item {
    cursor: pointer;
    gap: 2%;
    display: grid;
    grid-template-columns: 35px 56px auto 90px 110px 46px;

    background: rgba(255, 255, 255, .7);
  }
  
  .row-item__price {
    column-gap: 4px;
    justify-content: center;
  }

  .grab-zone {
    height: 100%;
    padding: 10px;
    margin: -10px;
    display: flex;
    justify-content: flex-end;
  }

  .item__config {
    height: 100%;
    justify-content: start;
  }
}

.collection-page__sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 24px;
  padding-right: 20px;
  align-items: stretch;
  overflow: visible;
}