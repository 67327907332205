.merchery-label {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &.no-placeholder {
    .placeholder {
      display: none;
    }

    .merchery-label__input:not(.merchery-label__textarea) {
      padding-top: unset;
    }
  }

  &.custom-number-input-wrapper {

    .merchery-label__input {
      padding-right: 14px;
    }
  }
  
  .password-visibility-btn {
    position: absolute;
    top: calc(50% - 11px);
    right: 13px;
    font-size: 22px;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
  
  &:hover .merchery-label__input {
    border: 1px solid #b5b9c4;
  }

  &.has-error {
    transition: all ease .05s;
    border-radius: 4px;

    &::before {
      content: unset;
    }

    .placeholder {
      color: rgb(215, 90, 90);
    }

    .merchery-label__input {
      border: 1px solid rgb(215, 90, 90);
    }
  }
}
  
.placeholder {
  position: absolute;
  top: 50%;
  left: 12px;
  font-size: 14px;
  line-height: 19px;
  transition: all ease .1s;
  opacity: 0.7;
  color: $blackSecondColor;
  pointer-events: none;
  transform: translate(0, -50%);

  cursor: text;
  user-select: none;
}

.merchery-label__inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;

  &.has-error {
    transition: all ease .05s;
    border-radius: 4px;

    .placeholder {
      color: rgb(215, 90, 90);
    }

    .merchery-label__input {
      border: 1px solid rgb(215, 90, 90);
    }
  }
}

.merchery-label__input {
  @include border-light-gray;
  background: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  transition: ease all .3s;
  line-height: 19px;
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 22px 0 12px;
  flex-grow: 1;
  box-sizing: border-box;

  &.merchery-label__textarea {
    height: unset;
    resize: none;
    min-height: 80px;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  &:active, &:focus-within {
    border: 1px solid #b5b9c4;
  }
}

.merchery-label__input:focus + .placeholder,
.merchery-label__input:not(:placeholder-shown) + .placeholder {
  top: 5px;
  left: 12px;
  font-size: 10px;
  line-height: 14px;
  transform: translate(0, 0);
}

.placeholder--animation-off:not(.no-placeholder) {
  .merchery-label__input + .placeholder {
    left: unset;
    top: 50%;
    transform: translate(0, -50%);
    right: 8px;
    font-size: 13px;
    line-height: 19px;

    &.placeholder--pos-right-16 {
      left: unset;
      right: 16px;
    }
  }
  .merchery-label__input {
    padding-top: unset;
  }
}

.merchery-label__input--height-38 {
  height: 38px;
}

.merchery-label__input--placeholder-gap {
  padding-top: 11px;
  
  &:not(.merchery-label__input--height-38) {
    height: 48px;
  }
}

.merchery-input__icon {
  position: absolute;
  opacity: .8;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}