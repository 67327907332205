@charset "utf-8";
@import '../../styles/utilities/variables';
@import '../../styles/utilities/mixins';

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;

  // &:focus {
  //   border: 1px solid red !important;
  // }
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	font-family: "Open Sans", "Arial", sans-serif;
	font-weight: normal;
	background-color: #fff;
}
input, textarea, select, .my-select .my-select__single-value, button {
	font-family: "Open Sans", "Arial", sans-serif;
	font-weight: 400;
	font-size: inherit;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	border: none;
	padding: 0;
	margin: 0;
	background-color: inherit;

  // &:focus {
  //   border: 1px solid red !important;
  // }
}
input:focus, textarea:focus, select:focus, button:focus {
	outline: none;
}
.my-select .my-select__single-value {
  margin-left: 4px;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	outline: none;
	border: none;
	cursor: pointer;
	padding: 0;
  color: inherit;
	background-color: transparent;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;
  text-transform: inherit;
}
a {
	text-decoration: none;
	color: unset;
	cursor: pointer;
}
button::-moz-focus-inner,
a::-moz-focus-inner,
select::-moz-focus-inner {
  border: 0;
  outline: none;
}
.my-select {
  display: flex;
  align-items: center;
  @include border-light-gray;
  border-radius: 4px;
  font-size: 14px;
  transition: ease all .3s;
  line-height: 19px;
  width: 100%;
  background: transparent;
  flex-grow: 1;
  margin-top: 12px;
  height: 48px;

  & .my-select__control {
    width: 100%;
  }
  & > span {
    display: none;
  }
}
a, button, select, *:focus {
  outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[disabled] {
	background: #f5f5f5; 
	color: #888888;
}
input::-webkit-calendar-picker-indicator {
  display: none;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
b, strong {
	font-weight: bold;
}

html, body, #root, .merchery-wrapper, .merchery-inner {
	background-color: $bgColor;
	display: flex; 
	flex-direction: column;
	flex-grow: 1;
	min-height: 100%;
	min-width: 903px;
}

.blue-color {	color: $activeSecondColor;}
.red-color {color: #F47373;}
*[disabled] {opacity: .7;}

span.ruble-symbol {
	font-family: "PT Sans", "Arial", sans-serif;
}

.flex-center {
  @include flex-center;
}

.merchery-inner {
	flex-direction: row;
	width: 100%;
	max-width: 1440px;
  margin: 0 auto;
}

.merchery-wrapper.sign-in-wrapper {
  background-color: white;
  
  & .merchery-inner {
    position: relative;
    min-height: 420px;
    background-color: white;
  }
}

.merchery-wrapper.filter .brightness-filter {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.15);
  transition: background-color .3s;
  user-select: none;  
}

.infinite-scroll-component {
  overflow: unset !important;
}

.main-wrapper {
  padding: 20px 48px 50px;
  color: $blackColor;
  width: 100%;
  background: #F9F9F9;

  h1 {
    font-size: 24px;
    line-height: 33px;
  }
}

.display-none {
  display: none;
}

.top-page-panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: $blackSecondColor;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 15;
  color: white;

  &.fixed {
    position: fixed;
  }

  .left {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 16px;
    line-height: 22px;

    .icon-div {
      padding: 10px;
      margin: -10px 15px -10px 0;
      display: flex;
      border: transparent 1px solid;
      transition: ease box-shadow .2s, ease border .2s;

      &:focus, &:hover {
        border: 1px solid rgba(255, 255, 255, 0.25);
      }
    }
  }
  
  .right {
    display: flex;
    height: 100%;
    align-items: center;
    column-gap: 13px;
    
    .blue-btn {
      border: transparent solid 1px;

      &:hover, &:focus {
        box-shadow: none;
        border: 1px solid rgba(255, 255, 255, 0.25);
      }
    }
    .dark-btn {
      @include dark-btn;
      transition: ease box-shadow .2s, ease border .2s;
    }
  }

  .sub-text {
    font-size: 12px;
    font-weight: 300;
    color: $lightGrayColor;
    opacity: 0.8;

    &.active {
      opacity: 1;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.top-page-panel__inner {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-left: 17px;
  padding-right: 30px;
  align-items: center;
  height: 100%;
}

.blue-btn {
  @include btn;
}
    
.white-btn {
  @include white-btn
}
.red-btn {
  @include red-btn;
}

.currency-pt-sans {
  line-height: 20px;
  font-family: "PT Sans", "Arial", sans-serif;
  font-weight: 400;
}

.loader-container {
  display: flex;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
    object-fit: contain;
  }
}

.grab-zone {
  display: flex;
  box-sizing: content-box;

  img {
    width: 8px;
    user-select: none;
  }
}

.default-blue-btn {
  @include btn
}

.has-error {
  position: relative;
  
  &::before {
    position: absolute;
    right: 10px;
    top: 5px;
    content: attr(messagetext);
    color:rgb(215, 90, 90);
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    pointer-events: none;
    z-index: 0;
  }
}

.custom-number-input-arrow {
  @include flex-center;
  position: absolute;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  right: 2px;
  
  &:not(:disabled) {
    &:hover, &:focus {
      background: $lightGrayColor;
    }
  
    &:active {
      background: $activeSecondColor;
      color: white;
    }
  }

  &.inc-arrow {
    top: 4px;
  }

  &.dec-arrow {
    bottom: 4px;
  }

  i {
    font-size: 12px;
  }
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.merchery-wrapper .Toastify__toast-container {
  max-width: 360px;
  width: max-content;
  min-width: 200px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  
  .Toastify__toast--error {
    background: #e9e9e9;
    border: rgb(215, 90, 90) 1px solid;
    color: rgb(215, 90, 90);
    min-height: 40px;
    border-radius: 4px;
  }

  .Toastify__close-button {
    color: rgb(215, 90, 90);
    margin-left: 10px;
  }
}

.app-loader-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 212px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
    
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.local-spinner {
  position: absolute;
  
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.app-loader {
  margin: auto;
  background: none;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0,0,0,0.3);
  justify-content: center;
  align-items: center;
}

.error-boundary {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 20px;
}

.visual-separator-line {
  height: 1px;
  background:#E6E6E6;
  width: 100%;
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
  & > * {
    flex-basis: 49%;
  }
}

.small-font {
  font-size: 14px;
}

.my-picture {
  height: 100%;
  width: 100%;
}

.my-picture__img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.my-btn {
  padding: 9px 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
}

.text-link {
  @extend .header-font-m;
  color: $activeSecondColor;

  &:hover, &:focus {
    text-shadow: 0 0 10px rgba(36, 85, 214, 0.3);
  }
}

.sorting-arrows {
  width: 12px;
  height: 12px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: center;
  visibility: hidden;
  position: absolute;
  right: -14px;

  &.active {
    visibility: visible;
  }

  .sort-arrow {
    opacity: 0.3;

    &.active, &:hover {
      opacity: 1;
    }

    &.ascending-arrow {
      transform: rotate(180deg);
      margin-top: -10px;
      padding-bottom: 10px;
    }
    
    &.descending-arrow {
      margin-bottom: -10px;
      padding-bottom: 10px;
    }
  }
}

.merchery-wrapper .tox-tinymce {
  min-height: 100px;
}

.height-48 {
  height: 48px;
}

.merchery-wrapper .__react_component_tooltip.place-top::before {
  content: unset;
}

.merchery-date-picker {
  position: relative;
  overflow: visible;
}

.merchery-color-picker {
  position: relative;
}

.merchery-date-picker--picker {
  .react-datepicker-time__header {
    &::before {
      content: 'время';
      position: absolute;
      text-align: center;
      line-height: 100%;
      right: 0;
      left: 0;
      background: #f0f0f0;
    }
  }
}

.merchery-date-picker__open-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);

  cursor: pointer;
  padding: 5px;

  img {
    width: 20px;
    height: 20px;
  }
}

.common-table-loader {
  display: flex;
  flex-direction: column;
  width: 100%;

  svg {
    width: 100%;
  }
}

.grayColor {
  background: #E4E4E4;
  color: $blackSecondColor;
}

@media(max-width: 1180px) {
	.merchery-wrapper {
		grid-template-areas: 'a a'
		'c c';
		position: relative;
	}
  .main-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media print {
	html, body{
		height: 297mm;
		width: 210mm;
	}

  @page {
    margin: 10mm 10mm 10mm 20mm;
  }

	*, *:before, *:after { 
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
    border-color: #000 !important;
	}
  
	a[href^="http"]:after {
		content: " (" attr(href) ")";
	}
}

@import '../../styles/order-page';
@import '../../styles/header';
@import '../../styles/orders';
@import '../../styles/sidebar';
@import '../../styles/icofont.css';
@import '../../styles/confirm-window';
@import '../../styles/popup';
@import '../../styles/delivery';
@import '../../styles/sign-in';
@import '../../styles/create-order';
@import '../../styles/dropzone';
@import '../../styles/moy-sklad';
@import '../../styles/subscriptions';

@import '../../components/main-pages/collections/collections';
@import '../../components/main-pages/clients/clients';
@import '../../components/main-pages/products/products';
@import '../main-pages/categories/categories';
@import '../../components/main-pages/products/product-page-modules/characteristics-modules/characteristics';
@import '../../components/main-pages/orders/refunds/refunds';
@import '../../components/main-pages/sets/sets';
@import '../../components/main-pages/settings/settings';

@import '../_utility-components/possible-values-list/possible-values-list';
@import '../_utility-components/select-input/select-input';
@import '../_utility-components/switcher/switcher';
@import '../_utility-components/input/input';
@import '../_utility-components/button/button';
@import '../_utility-components/multi-select/multi-select';
@import '../_utility-components/checkbox/my-checkbox';
@import '../_utility-components/trash-btn/trash-btn';
@import '../_utility-components/search-input/search-input';
@import '../_utility-components/common-table/table';
@import '../_utility-components/visibility-select/visibility-select';
@import "../_utility-components/page-is-out-of-date/_page-is-out-of-date";