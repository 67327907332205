@mixin border-light-gray {
  border: 1px solid $lightGrayColor;
}

@mixin main-input {
  @include border-light-gray;
	box-sizing: border-box;
	background: $whiteColor;
	border-radius: 4px 0px 0px 4px;
	width: 100%;
	color: $blackSecondColor;
	font-size: 14px;
	line-height: 18px;

	&::placeholder {
		@include text-style-1;

    user-select: none;
		line-height: 36px;
	}
}

@mixin text-style-1 {
	font-size: 13px;
	line-height: 18px;
	color: rgba($blackSecondColor, .7)
}

@mixin btn-icon {
	top: 50%;
	right: 13.2px;
	transform: translate(0, -50%);
	position: absolute;
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin popup-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 17px;  

  &:first-of-type {
    padding-top: 12px;
  }
  &:last-of-type {
    padding-bottom: 12px;
  }

  &:not(:first-of-type) {
    padding-top: 8px;
  }
  &:not(:last-of-type) {
    padding-bottom: 8px;
  }
}

@mixin popup-btn-hover {
  background-color: rgba(76, 93, 250, 0.05);
}

@mixin btn {
  border-radius: 4px;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $whiteColor;
  height: 40px;
  background: linear-gradient(180deg, #517EF2 0%, $activeSecondColor 100%);
  transition: box-shadow ease .2s;
  border: 1px solid transparent;
  
  &:hover, &:focus {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba($blackSecondColor, 0.25);
  }
}

@mixin text-btn {
  display: flex;
  justify-content: center;
	align-items: center;
	color: rgba($blackSecondColor, 0.25);
  
  &:hover, &:focus {
  	color: $activeSecondColor;
  }
}

@mixin white-btn {
  @include btn;
  @include border-light-gray;
	
	color: $blackSecondColor;
  background: $whiteColor;
}

@mixin gray-btn {
	@include btn;
	
	border: 1px solid $grayColor;
	color: $blackSecondColor;
  background: #E5E5E5;
}

@mixin dark-btn {
	@include btn;
	
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: #FFFFFF;
  background: $whiteColor;
  background: $blackSecondColor;
}

@mixin red-btn {
	@include btn;
	
	background: linear-gradient(180deg, #F47373 0%, #D75A5A 100%);
}

.padding-left-18 {
  padding-left: 18px;
}

.flex-gap-24 {
  gap: 24px;
}

.flex-gap-18 {
  gap: 18px;
}

.flex-gap-16 {
  gap: 16px;
}

.cursor_pointer {
  cursor: pointer;
}

.width-228 {
  width: 228px;
}

.header-font-xl {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.header-font-l {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.header-font-m {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.header-font-s {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
}

.header-font-xs {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.header-font-xxs {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.text-font-l {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.text-font-m {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.text-font-s {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.text-font-xs {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.wide-header-font-xs {
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
}

.wide-text-font-xxs {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
}

.separate-line {
  border-bottom: 1px solid #E6E6E6;
}

.sticky-header {
  position: sticky;
  top: -1px;
  z-index: 1;
  will-change: transform;
}

.text-btn {
  @include text-btn;
}