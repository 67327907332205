
.orders-wrapper {
  position: relative;
}

.orders-header {
  display: grid;
  row-gap: 12px;
  justify-content: space-between;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
}

.orders-header_right-inner {
  display: flex;
  align-items: center;
  column-gap: 10px;

}

.orders-page__filters-and-export {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 70px;
  margin-bottom: 8px;
}

.search-and-filters-bar {
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  position: relative;
  margin-right: 162px;

  .search-button {
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding: 7px;
    box-sizing: content-box;
    display: inline-block;
    font-size: 12px;
    transition: ease box-shadow .2s;

    &:hover, &:focus {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    }

    &.arrow-left-icon {
      // flex: 1 0 auto;      margin: 0;
      position: absolute;
      left: -40px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .search {
    height: 100%;
    width: 100%;
    display: block;
    background: white;

    input {
      @include main-input;

      background-color: unset;
      padding-left: 38px;
      height: 100%;
    }
  }
}

.search-close-bar-btns {
  display: flex;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: .1em;
  color: $blackSecondColor;

  * {
    padding: 10px 4.5px 8px;
    border-bottom: 2px transparent solid;
    margin: 0 10.5px;
    cursor: pointer;
    text-transform: uppercase;
    opacity: 0.7;

    &.active-status {
      color: $activeColor;
      opacity: 1;
      border-bottom: 2px $activeColor solid;
    }

    &:not(.active-status):hover, &:not(.active-status):focus {
      border-bottom: solid 2px $blackSecondColor;
      opacity: 1;
    }

    &.by-manager {
      position: relative;
      
      img {
        top: 50%;
        right: -8.5px;
        transform: translate(0, -50%); 
        position: absolute;
      }
    }
  }
}

.search-open-bar-btns {
  display: flex;
  height: 100%;
}

.status_filter-btn__text {
  padding-left: 24px;
}

.filter-btn-inner {
  display: flex;
  align-items: center;
  padding: 0 33px 0 12px;
  height: 100%;
  width: 100%;
  transition: ease box-shadow .2s;

  &:hover, &:focus {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
}

.filter-btn {
  @include text-style-1;
  @include border-light-gray;

  border-left: 0;
  display: flex;
  cursor: pointer;
  height: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%);
  position: relative;
  width: max-content;

  &:last-of-type {
    border-radius: 0px 4px 4px 0px;
  }
}

.filter-btn-inner img {
  @include btn-icon;
}

.general-popup.status .popup-element {
  .checkbox {
    margin-right: 11px;
  }
  &.active .checkbox {
    border-color: #5079E0;
    i {
      display: unset;
      color: #5079E0;
    }
  }
}
.order-export-btn {
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $blackSecondColor;
  opacity: 0.8;
  cursor: pointer;
  padding: 10px;
  width: 104px;
  text-align: right;
  transition: ease box-shadow .2s;

  &:hover, &:focus {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
  
  i {
    font-size: 15px;
  }
}

.orders-table__cells-template {
  display: grid;
  grid-template-columns: 74px 74px auto 152px 109px 100px 28px 74px 40px;
  column-gap: 4px;
}

#orders-table {
  width: 100%;
  position: relative;
  row-gap: 8px;

  &.hide-table {
    display: none;
  }

  .table__inner {
    background-color: #FFFFFF;
  }

  .client-info-popup-open-btn {
    display: flex;
    color: #AFAFAF;
    position: absolute;
    padding: 10px;
    cursor: pointer;
    font-size: 0;
    right: -30px;
    top: 50%;
    transform: translate(0, -50%);

    &:hover, &:focus {
      font-size: 14px;
      color: $activeSecondColor;
    }
  }

  .table__row {
    padding: 5px 0;
    cursor: pointer;
    vertical-align: middle;

    &:hover, &:focus {
      border: 1px transparent solid;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);

      .client-info-popup-open-btn {
        font-size: 14px;
      }

      .tt-delivery > div {

        & > span {
          border-bottom: dashed 1px $blackSecondColor;
        }
      }
    }
    
    &:last-of-type:hover {
      border: {
        bottom: 1px transparent solid
      }
    }
    
    &.active, &.active:hover {
      border: 1px solid #517EF2;
      z-index: 1;

      .checkbox {
        @include flex-center;
        border: 1px solid $activeSecondColor;

        div {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $activeSecondColor;
        }
      }
    }
  }

  .tt-buyer {
    padding-right: 22px;
    display: flex;
    align-items: center;
  }

  .tt-buyer__popup-opener__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .tt-cost {
    justify-content: right;
    white-space: nowrap;
    padding-right: 40px;
    position: relative;
    
    span {
      font-family: "PT Sans", Arial, sans-serif;
      font-size: 13px;
    }
  }

  .order-row__paid-status {
    max-width: 20px;
    max-height: 20px;
    height: auto;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);

    img {
      height: 100%;
      width: 100%;
    }
  }

  .tt-delivery {
    & > div {
      position: relative;

      & > span > .general-popup.client-info-popup {
        left: 0;
        right: unset;
        transform: none;
        bottom: calc(100% + 15px);
      }
    } 
  }

  .tt-delivery-popup-wrapper {
    position: relative;
  }
  
  .tt-status, .tt-manager {
    justify-content: center;
    position: relative;
  }

  .tt-refund-flag {
    position: relative;
    min-width: 28px;
  }

  .refund-flag__icon-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .tt-status {
    .select-btn-inner {
      transition: ease box-shadow .2s;
      color: white;
      font-size: 10px;
      line-height: 14px;
      font-weight: 600;
      text-transform: uppercase;
      width: 100%;
      height: 100%;

      &:hover, &:focus {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      }
    }

    .select-btn {
      margin: auto;
      width: 90px;
      height: 22px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &.grayColor {
        background: #E4E4E4;

        .select-btn-inner {
          color: $blackSecondColor;
        }
      }
    }


    &.orange {background-color: $orangeColor; color:$whiteColor}
  }

  .tt-manager .select-btn {
    width: 24px;
    height: 24px;
  }

  .tt-payment > div {
    text-align: right;

    &.green {color: $greenColor}
    &.orange {color: $orangeColor}
    &.red {color: $pinkColor}
  }

  .tt-config {
    width: max-content;
    cursor: pointer;
  }
}
  
.tt-id {
  position: relative;

  .status-and-id__wrapper {
    display: flex;
    align-items: center;
  }

  .status-indicator{
    width: 13px;
    height: 13px;
    margin-right: 12px;
    border-radius: 2px;
    background: #E4E4E4;

    &.green {background-color: $greenColor}
    &.orange {background-color: $orangeColor}
    &.red {background-color: $pinkColor}
  }
}

.tt-select-outside {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 100%;
  top: 0;
  left: -48px;
  position: absolute;

  .checkbox {
    margin: auto;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $grayColor;
    display: none;
    transition: ease box-shadow .2s;
  }

  &:hover, &:focus {
    .checkbox {
      display: flex;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    }
  }
}

.tt-config-wrapper {
  display: flex;
}

.config-popup-wrapper {
  position: relative;

  button {
    padding: 10px;
    margin: -10px;
    display: flex;
    align-items: center;
  }
}

.general-popup.config-popup {
  left: unset;
  right: -10px;
}

.manager-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: auto;
  transition: ease .2s box-shadow;
  width: 100%;
  height: 100%;

  &:hover, &:focus {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
}

.general-popup.client-info-popup {
  width: max-content;
  min-width: 293px;
  max-width: 400px;
  left: unset;
  top: unset;
  right: 0;
  bottom: calc(100% + 12px);
  transform: translate(80%);
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 18px 18px 22px 19px;
  border: 1px solid #c4c4c4a4;
  row-gap: 17px;
  cursor: auto;

  & > * {
    display: flex;
  }
  
  .contacts {
    flex-direction: column;
    font-size: 12px;
    line-height: 16px;

    h4 {
      font-weight: 600;
      margin-bottom: 6px;
    }

    div {
      margin-bottom: 4px;
    }

  }

  .history {
    width: 100%;
    column-gap: 20px;
    justify-content: space-between;
    position: relative;
    min-height: 35px;

    .div-container {
      display: flex;
      flex-direction: column;
      width: max-content;
      row-gap: 3px;
      // min-width: 28%;
    }

    .title-div {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    .text-div {
      display: flex;
      flex-wrap: wrap;
      column-gap: 3px;

      &:not(.orders-by-user) > .bold {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
      }
    }
  }

  .separate-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.orders-by-user {
  color: #517EF2;
  cursor: pointer;
}

.empty-table {
  font-size: 24px;
  line-height: 28px;
  background: #F9F9F9;
  margin-top: -10px;
  padding-top: 30px;
  display: flex;
}

.managers-on-page {
  display: flex;
  //flex-direction: row-reverse;
}

.manager-on-page {
  &:not(:last-of-type) {
    margin-right: -12%;
  }
}

.manager-icon {
  @include flex-center;
  //position: absolute;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  color: $whiteColor;
  font-size: 12px;

  &.current-manager-icon {
    position: unset;
  }

  &.manager-icon_size--small {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}

.selected-top-page-menu {
  position: fixed;
  height: max-content;
  top: 0;
  left: -1px;
  right: 0;
  background-color: $blackSecondColor;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  color: $whiteColor;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  z-index: 15;

  .selected-top-page-menu-inner {
    padding: 0 59px;
    
    .left {
      font-size: 14px;
    }
  }

  .left {
    display: flex;
    height: 100%;

    & > div:not(.selected-top-page-menu-btn) {
      display: flex;
      align-items: stretch;
      height: 100%;
    }

    & > div {
      margin: 0 20px 0 -10px;
      cursor: pointer;
      position: relative;

      .selected-top-page-menu-btn {
        padding: 0 10px;
        column-gap: 8px;

        &:hover, &:focus {
          text-shadow: 0 0 8px;
        }
      }

      & > span {
        opacity: .8;
        text-align: left;
      }
    }
    
    .uncheck-all-items {
      position: relative;
      padding: 0 40px 0 42px;
      margin-left: -42px;
      cursor: pointer;

      & > div {
        @include flex-center;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
        position: absolute;
        border: 1px solid $activeSecondColor;
        width: 16px;
        height: 16px;
        border-radius: 50%;

        div {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $activeSecondColor;        
        }
      }
    }
  }

  .right > div {
    color:$pinkAnotherColor;
    cursor: pointer;
    padding: 10px;
    margin: -10px;
  }
}

.selected-top-page-menu-btn {
  display: flex;
  align-items: center;
  height: 100%;

  span {
    text-align: left;
  }
}

.applied-filters {
  display: flex;
  flex-wrap: wrap;
  background-color: $bgColor;
  color: $activeSecondColor;
  padding-bottom: 15px;
  max-width: 75%;

  div {
    position: relative;
    background: #FFFFFF;
    border: 1px solid $activeSecondColor;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    height: 22px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 12px;
    margin-right: 8px;
    margin-bottom: 10px;

    i {
      color: $activeSecondColor;
      display: block;
      position: absolute;
      font-size: 13px;
      right: 0;
      padding: 4px;
      cursor: pointer;
      top: calc(50% - 10px);
    }
  }
}

.infinite-scroll-component svg, .table-filters-list svg {
  width: 100%;
}

.add-manager {
  cursor: pointer;
  background: url('../img/add-manager.png') no-repeat 50% 50%;
}

.order-create-btn {
  @include btn;
}

.order-delete-popup {
  row-gap: 12px;
}

@media(max-width: 1180px) {
  #orders-table .table__header .table__cell {
    font-size: 9px;
  }
  .orders-wrapper {
    margin-left: 32px;
  }
}