

.collections__table {
  .table__header {
    height: 48px;
  }
}

.table__row.sets__row {
  height: 88px;
}

.table__row.sets__row,
.table__header.sets__row {
  display: grid;
  grid-template-columns: 60px 56px auto 150px 70px 74px;
  column-gap: 20px;
  border: none;
  border-bottom: 1px solid #E4E4E4;

  .checkbox-wrapper {
    justify-content: flex-end;
  }

  .control-cell {
    justify-content: right;
    column-gap: 20px;
    height: 100%;
  }
}

.set-row {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  border: 0 solid #E4E4E4;
  border-bottom-width: 1px;

  &.collections__add-items-rows--template {
    display: grid;
    grid-template-columns: 16px 56px auto 20%;
    padding-left: 24px;
    padding-right: 24px;
    column-gap: 2%;
  }
}

.row-item.set-products__add-items-rows--template {
  display: grid;
  grid-template-columns: 16px 56px auto 20% 20%; 
  padding-left: 24px;
  padding-right: 24px;
  column-gap: 2%;

  .row-item__price {
    justify-content: left;
  }
}


.show-products__btn {
  cursor: pointer;
  display: flex;
  padding: 10px;
  margin: -10px;

  &.clickable {
    color: rgb(36, 85, 214);
  }
}

.set-row:not(.disabled) {
  .show-products__btn {
    &:focus, &:hover {
      text-shadow: 0 0 10px rgba(36, 85, 214, 0.3);
    }
  }
}

.show-products__btn--shown i {
  transform: rotate(-90deg);
}