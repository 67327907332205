.product-page__characteristics {
  display: flex;
  flex-direction: column;
  position: relative;
  //padding-top: 16px;
  //padding-bottom: 16px;
}

.characteristic {
  background-color: $bgColor;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 0 24px;
}

.characteristic--editing {
  background-color: #F5F8FF;

  .characteristic-header__wrapper {
    height: unset;
    padding-top: 28px;
  }

  .characteristic-header {
    padding-top: 14px;
    align-items: flex-end;
    border-radius: 0;
    column-gap: 24px;
    padding-right: 40px;
  }


  .characteristic-labels {

    &.multiselect-items-wrapper {
      padding-top: 10px;
    }
  }

  .characteristic-labels__header {
    margin-bottom: -5px;
  }

  .characteristic-header__right-part {
    position: absolute;
    top: -40px;
    right: 41px;
  }
}

.characteristic-header__wrapper {
  display: flex;
  flex-direction: column;
  margin: -1px;
  background-color: inherit;
  position: sticky;
  z-index: 1;
  border: 1px solid #E6E6E6;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 16px;
  padding-top: 16px;
  gap: 8px;
}

.characteristic-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.char-header__select-input {
  max-width: 500px;
  cursor: pointer;
  width: auto;
}

.characteristic-header__title {
  color: $blackSecondColor;
}

.characteristic-header__title--input {
  flex: 1 1 50%;
}

.characteristic-header__category--input {
  flex-basis: 50%;
  position: relative;
}

.characteristic-header__right-part {
  justify-content: flex-end;
  display: flex;

  .drop-down-menu__open-btn {
    padding: 15px;
    margin: -15px;
  }
}

.drop-down-menu__wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.drop-down-menu__open-btn {
  padding: 5px;
  margin: -5px;
  display: flex;
  align-items: center;
}

.drop-down-menu {
  right: -10px;
  left: unset;

  .popup-element {
    padding: 12px 17px;
    // color: $redColor;
    cursor: pointer;

    &:hover, &:focus {
      @include popup-btn-hover;
    }
  }
}

.drop-down-menu_position {
  right: 50%;
  transform: translate(30%, 0);
}

.characteristic-labels {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
}

.char-label {
  padding: 0 24px;
  position: relative;
}

.char-label--editing {
  display: grid;
  position: relative;
  grid-template-areas: "grab title type trash";
  grid-template-columns: 12px auto 27% 36px;
  grid-template-rows: auto;
  column-gap: 8px;
  padding: 0 40px 0 24px;

  &.char-label_type_number {
    grid-template-areas: "grab title unit type trash";
    grid-template-columns: 12px auto 27% 27% 36px;
  }

  &.char-label_type_multiselect {
    grid-template-areas: "grab title type trash"
                         "ms-item ms-item ms-item ms-item";
    grid-template-columns: 12px auto 27% 36px;
  }

  &.multiselect-item {
    grid-template-areas: "grab title trash";
    grid-template-columns: 12px auto 36px;
  }
}

.char-label_sub-label {
  padding-left: 4px;
  padding-right: 0;
}

.multiselect-items-wrapper {
  grid-area: ms-item;
}

.multiselect-item__name {
  position: relative;
}

.char-label_type-select {
  grid-area: type;
}
.char-label_unit-select {
  grid-area: unit;
}

.char-label__header {
  margin-bottom: 8px;
}

.char-label__bubble {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 30px;
  padding: 4px 12px;
  font-weight: 400;
  width: max-content;

  .char-label__bubble__half-opacity {
    opacity: .5;
  }
}

.char-label__body_textarea {
  margin-bottom: 5px;
}

.characteristic-options-switcher__wrapper {
  padding-top: 24px;
}

.characteristics-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.characteristics-bottom__btn {
  @include text-btn;
  @include flex-center;
	color: $activeSecondColor;

  i {
    font-size: 17px;
    margin-bottom: -3px;
    margin-right: 19.5px;
  }

  &:hover, &:focus {
    &:not(:disabled) {
      color: rgba($activeSecondColor, 0.75);
    }
  }
}

.characteristics-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 2;
  top: -1px;
  padding-top: 24px;
  padding-bottom: 24px;
  will-change: transform;
  background: white;
}

.characteristics-top__btn-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.characteristics-bottom__btn-wrapper {
  display: flex;
  gap: 16px;
  padding: 0 40px 32px 24px;
  position: relative;
}


@media(max-width: 1180px) {
  .product-page__characteristics {
    padding: 0;
  }
}