.refund-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .search-state {
    margin-left: 40px;
  }
}

.refunds-table {
  gap: 9px;

  .bold {
    font-weight: 600;
  }

  .table__row {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;

    &.focused {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .table__header {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1;
    text-transform: uppercase;
  }

  .reverse-padding {
    margin: -5px 0;
  }

  .select-btn {
    position: relative;
  }
}

.refund-table__cells-template {
  display: grid;
  grid-template-columns: 55px 69px 80px auto 120px 100px 160px;
  column-gap: 25px;
  
  &.table__row .table__cell {
    padding: 15px 0 18px;
    
    &.refund-table__btn-wrapper {
      padding: 14px 0 17px;
    }
  }
}

.refund-status__cell-btn {
  border-radius: 4px;
  text-transform: uppercase;
  padding: 4px 18px;
}