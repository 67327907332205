
.table {  
  display: flex;
  flex-direction: column;
}

.table__inner {
  position: relative;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #292D38;
}

.table__header {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  .table__cell { 
    position: relative; 
    user-select: none;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .table__cell.clickable:hover {
    button {
      font-weight: 600;
      padding: 5px;
      margin: -5px;
    }
    cursor: pointer; 

    .sorting-arrows {
      visibility: visible;
    }
  }
  .table__cell.clickable button:focus {
    font-weight: 600;

    & + .sorting-arrows {
      visibility: visible;
    }
  }
}

.table__cell-inner {
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
}

.table__row {
  align-items: center;
  border: 1px solid #E4E4E4;
  cursor: pointer;
  position: relative;
  background: rgba(255,255,255, 0.8);

  &.hidden > *:not(.control-cell) {
    opacity: .5;
  }

  .grab-zone {
    padding: 10px;
    margin: -10px;
  }

  .dot-menu {
    display: flex;
    align-items: center;
    visibility: hidden;
  }

  // &.active {
  //   &::after {
  //     background-color: #517EF2;
  //   }
  // }

  // &::after {  
  //   top: 0;
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   transform: translate(-1px, -1px);
  //   height: 1px;
  //   width: calc(100% + 2px);
  //   background-color: transparent;
  // }

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);

    .dot-menu {
      visibility: visible;
    }
  }
}

.table__cell {
  display: flex;
  align-items: center;
  word-break: break-all;
  column-gap: 4px;
  color: $blackSecondColor;
  padding: 5px;

  &:first-of-type {
    padding-left: 16px;
  }

  &:last-of-type {
    padding-left: 14px;
    padding-right: 13px;
  }
}
.left-align { display: flex; justify-content: left; }
.center-align { display: flex; justify-content: center; }
.right-align { display: flex; justify-content: right; }

.image__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}